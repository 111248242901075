import { useContext } from 'react'
import ButtonLink from '~/src/components/mui-wrappers/buttons/ButtonLink'
import RoutesEnum from '~/src/router/enums/routes.enum'
import { useTranslations } from '~/src/hooks/useTranslations'
import ResponsiveDialogContext, {
  ResponsiveDialogContextInterface
} from '~/src/components/mui-wrappers/responsive-dialog/ResponsiveDialogContext'
import { useEoValue } from '~/src/hooks/useEoState'
import { LAYOUT_STATE } from '~/src/stores/layout'
import { Box } from '@mui/material'

const GoOrderStatusButton = () => {
  const { t } = useTranslations()
  const context = useContext<ResponsiveDialogContextInterface>(ResponsiveDialogContext)
  const { isMobile } = useEoValue(LAYOUT_STATE)

  const handleOnClick = () => {
    context?.toggle && context.toggle()
  }

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      width: isMobile ? '100%' : 'inherit'
    }}>
      <ButtonLink
        fullWidth={isMobile}
        color='primary'
        variant='contained'
        displayIcon={false}
        disableElevation
        linkProps={{
          href: `${RoutesEnum.ORDERS}/${RoutesEnum.UPCOMING}`,
          resolve: true
        }}
        onClick={handleOnClick}
      >
        {t('global.continue')}
      </ButtonLink>
    </Box>
  )

}

export default GoOrderStatusButton
